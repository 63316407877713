import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { Ticket, TicketStatusEnum, User } from "../../../services/swagger";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReorderableList from "../../molecules/ReorderableList";
import { TicketService, UserService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";

export interface TicketAssigneesProps {
  ticket: Ticket;
}

export default function TicketAssignees(props: TicketAssigneesProps) {
  const { t } = useTranslation("Ticket");
  const { ticket } = props;
  const [users, setUsers] = useState<string[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const refresh = useRefresh();

  const loadUsers = async () => {
    const res = await UserService.getAllUsersList();
    if (res?.data?.data) setUserList(res.data.data.docs);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    setUsers(ticket.assignees.map((assignee) => assignee._id || ""));
  }, [ticket]);

  const handleChange = async (list: string[]) => {
    const oldList = [...list];
    console.log(list);
    setUsers(list);
    try {
      await TicketService.updateTicket({
        id: ticket._id || "",
        updateTicketBody: {
          assignees: list,
          city: ticket.city,
          company: ticket.company,
          departmentId: ticket.department._id || "",
          priority: ticket.priority,
          status: ticket.status,
          title: ticket.title,
          location: ticket.location,
          number: ticket.number,
          person: ticket.person,
        },
      });
    } catch (err) {
      setUsers(oldList);
    }
  };

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <Stack spacing={1}>
        <Box>
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {t("Assignees")}
          </Typography>
        </Box>
        <ReorderableList
          unique
          disabled={
            ticket.status === TicketStatusEnum.PendingConfirmation ||
            ticket.status === TicketStatusEnum.Complete
          }
          items={users}
          onChange={handleChange}
          renderItem={(value) =>
            userList.find((user) => user._id === value)?.name
          }
          addList={userList
            .filter((user) => !users.includes(user._id as string))
            .map((user) => ({
              name: user.name,
              value: user._id || "",
            }))}
          confirmAddition
          confirmDeletion
          label={""}
        />
      </Stack>
    </Paper>
  );
}
