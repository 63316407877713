export const englishTranslations = {
  HomePage: {
    MainHeader: "24/7 Dedicated Support",
    SubHeader:
      "Our dedicated team of experts is ready to provide you with prompt and reliable support, ensuring that your concerns are addressed swiftly.",
    OpenTicketButton: "Open A Ticket",
    CustomerSupport: "Customer Support",
    ModuleOne: {
      title: "Expert Guidance, Every Step of the Way",
      body: "Benefit from our wealth of knowledge and experience to navigate any issue seamlessly.",
    },
    ModuleTwo: {
      title: "Tailored Solutions for Your Needs",
      body: "Our support services are adaptable and personalized, ensuring that you receive solutions tailored specifically to your requirements.",
    },
    ModuleThree: {
      title: "Reliability and Trustworthiness",
      body: "Our support is delivered with the utmost reliability and security, providing you with the confidence you need to focus on what matters most.",
    },
    HowToCreateTicket: "How to Create a Ticket",
    StepOne:
      'Register and login to your account. Go to the "My Tickets" tab and click “New Ticket” to create your ticket',
    StepTwo:
      "Please wait while one of our staff responds, this process may take a while. Afterwards, we will provide you with more details on how to fix your issue",
    StepThree:
      "After a response from our side, we can finally get to fixing the issue by assigning a technical support member to assist you remotely or on-site.",
    GetStarted: "Get Started",
    Step: "STEP",
  },
  Footer: {
    WhatWeDo: "WHAT WE DO",
    WhatWeDoBody:
      "Apart from project consultancy, installation and post-installation services, we also provide TDM/IP PBX and Call Center solutions, fiber optic cabling, termination and testing services, and sales and after-sales support of network and transmission equipment.",
    Contact: "CONTACT",
    FollowUs: "FOLLOW US",
    Copyright: "Copyright",
  },
  AccountInfoPage: {
    AccountInfo: "Account Info",
    Name: "Name",
    Username: "Username",
    Email: "Email",
    Phone: "Phone",
    Company: "Company",
    Role: "Role",
    AccountStatus: "Account Status",
    Activated: "Activated",
    Inactive: "Inactive",
    MultiFactorAuthentication: "Multi-Factor Authentication",
    Authenticator: "Authentiator",
    ChangeYourPassword: "Change your password?",
    ClickHere: "Click here",
  },
  ForgotPasswordPage: {
    RequestCode: "Request Code",
    SubmitCode: "Submit Code",
    ChangeYourPassword: "Change your password",
    ForgotYourPassword: "Forgot your password?",
    ResetPasswordBody:
      "Please enter your email so we can send you a reset password code",
    Code: "Code",
    EmailAddress: "Email Address",
    CheckYourCode:
      "We have sent you a confirmation code. Please check your email and enter the code.",
    ConfirmationCode: "Confirmation Code",
    NewPassword: "New Password",
    BackToAccountInfo: "Back To Account Info",
    BackToLogin: "Back To Login",
    ChangePassword: "Change Password",
    DidntGetCode: "Didn't get a code?",
    TryAgain: "Try Again",
    SecondsLeft: "in {{seconds}}s",
  },
  Navigation: {
    Home: "Home",
    AllTickets: "All Tickets",
    MyTickets: "My Tickets",
    Management: "Management",
    AccountInfo: "Account Info",
    ChangePassword: "Change Password",
    Logout: "Logout",
    Dashboard: "Dashboard",
    Tickets: "Tickets",
    Storage: "Storage",
    Users: "Users",
    Departments: "Departments",
    Security: "Security",
    Logs: "Logs",
    Settings: "Settings",
    Login: "Login",
    Register: "Register",
    ForgotPassword: "Forgot Password",
  },
  ManageTicketPage: {
    Ticket: "Ticket",
  },
  CreateTicketPage: {
    CreateTicket: "Create Ticket",
  },
  SetupPage: {
    Setup: "Setup",
    Finish: "Finish",
    FullName: "Full name",
    Email: "Email",
    Username: "Username",
    Password: "Password",
    Finalize: "Finalize",
    ScanQrGuide:
      "Please scan the QR code on the authenticator app to login to the administrator",
    GoBack: "Go Back",
    Features: "Features",
    Back: "Back",
    Next: "Next",
    EmailSetup: {
      EmailSetup: "Email Setup",
      Server: "Server",
      From: "From",
      Username: "Username",
      Password: "Password",
      Port: "Port",
      Secure: "Secure",
    },
    FtpSetup: {
      FTPSetup: "FTP Setup",
      Host: "Host",
      Username: "Username",
      Password: "Password",
      Port: "Port",
      Secure: "Secure",
      UploadPath: "Upload Path",
    },
    OpenAISetup: {
      OpenAISetup: "Open AI Setup",
      ApiKey: "Api Key",
      AssistantID: "Assistant ID",
      OrganizationID: "Organization ID",
    },
    SapSetup: {
      SapSetup: "Sap Setup",
      Server: "Server",
      Database: "Database",
      Username: "Username",
      Password: "Password",
    },
  },
  LoginForm: {
    Header: "Welcome",
    Subheader: "Please login to your account here!",
    LoginInput: {
      label: "Login",
      placeholder: "Username or Email",
    },
    Password: "Password",
    DontHaveAccount: "Don't have an account yet?",
    Register: "Register",
    Login: "Login",
    ForgotPassword: "Forgot password?",
  },
  ActivateAccountDialog: {
    ActivateYourAccount: "Activate your account",
    PleaseEnterCode: "Please enter the code that was sent to your email",
    ActivationCode: "Activation Code",
    Cancel: "Cancel",
    Activate: "Activate",
  },
  OTPDialog: {
    OneTimePassword: "One-time password",
    PleaseEnterOtp:
      "Please enter the one-time password from your authenticator app",
    OtpCode: "OTP Code",
    Cancel: "Cancel",
    Confirm: "Confirm",
  },
  EmailConfirmationDialog: {
    EmailConfirmation: "Email Confirmation",
    PleaseEnterCode: "Please enter the code that was sent to your email",
    ConfirmationCode: "Confirmation Code",
    Cancel: "Cancel",
    Confirm: "Confirm",
  },
  RegisterForm: {
    Register: "Register",
    ToContactOurSupport:
      "To contact our support and make a ticket please sign up here.",
    Email: "Email",
    Company: "Company",
    Phone: {
      Label: "Phone",
      Placeholder: "Phone number",
    },
    Name: {
      Label: "Name",
      Placeholder: "ex. John Doe",
    },
    Username: {
      Label: "Username",
      Placeholder: "ex. johndoe",
    },
    Password: "Password",
    AlreadyHaveAccount: "Already have an account?",
    Login: "Login",
  },
  NotificationButton: {
    LoadMore: "Load more...",
    Notifications: "Notifications",
    ClearNotifications: "Clear Notifications",
    NoNotificationsFound: "No notifications found",
  },
  UploadButton: {
    Upload: "Upload",
  },
  UploadDialog: {
    Upload: "Upload",
    ClickToUpload: "Click to upload",
  },
  TaskDialog: {
    Cancelled: "Cancelled",
    Started: "Started",
    Ended: "Ended",
    Title: "Title",
    Description: "Description",
    Name: "Name",
    Action: "Action",
    SelectUserPlaceholder: "Select user to assign...",
    Comments: {
      Label: "Comments",
      Placeholder: "ex. Task needs re-evaluation at time X",
    },
    CancelTask: "Cancel Task",
    Delete: "Delete",
    Start: "Start",
    Stop: "Stop",
    AlreadyStarted: "Already Started?",
    StartAndEndDates: "Start & End Dates",
    Set: "Set",
    StartDate: "Start Date",
    EndDate: "End Date",
  },
  QRButton: {
    QR: "QR",
    PleaseScan: "Please scan the QR code in your authenticator application",
    Close: "Close",
  },
  FilterBox: {
    Clear: "Clear",
    Apply: "Apply",
    AddFilter: "Add Filter",
  },
  DataTable: {
    MaxRows: "Max rows",
    DisplayedRows: "{{info.from}}–{{info.to}} of {{info.count}}",
  },
  CreateUserDialog: {
    CreateUser: "Create User",
    Role: "Role",
    Email: {
      Label: "Email",
      Placeholder: "ex. john.doe@email.com",
    },
    Username: "Username",
    Company: "Company",
    Phone: "Phone",
    FullName: "Full Name",
    Password: "Password",
    EmailConfirmation: "Email Confirmation",
    OTPEnabled: "OTP Enabled",
  },
  CreateRecordDialog: {
    CreateRecord: "Create Record",
    Type: "Type",
    IP: {
      Label: "IP",
      Placeholder: "ex. 192.168.0.1",
    },
    Subnet: {
      Label: "Subnet",
      Placeholder: "ex. 255.255.255.0",
    },
  },
  ReorderableList: {
    NewPlaceholder: "New item",
  },
  CreateDepartmentDialog: {
    CreateDepartment: "Create Department",
    Name: "Name",
    Description: "Description",
    Administrative: "Administrative",
  },
  Atoms: {
    AskAiTooltip: "Ask AI",
    DeleteTooltip: "Delete",
    SummarizeTooltip: "Summarize",
    TranslateTooltip: "Translate",
  },
  AskAiDialog: {
    Description:
      "You can edit the content to remove any private information before confirming.",
    PromptLabel: "Prompt",
    Cancel: "Cancel",
    AskAI: "Ask AI",
  },
  AskAiResponseDialog: {
    AIResponse: "AI Response",
  },
  DateRanges: {
    Week: "Week",
    Month: "Month",
    Year: "Year",
    AllTime: "All Time",
  },
  EditButton: {
    Tooltip: "Edit",
    Save: "Save",
    Cancel: "Cancel",
  },
  FormInput: {
    True: "True",
    False: "False",
  },
  Dashboard: {
    ActiveTasks: "Active Tasks",
    Tickets: "Tickets",
    View: "View",
    NoActiveTasks: "There are no active tasks",
    TicketStatus: "Ticket Status",
    TicketsResolved: "Tickets Resolved",
    UnsolvedTickets: "Unsolved Tickets",
    PendingTickets: "Pending Tickets",
    SolvedTickets: "Solved Tickets",
    AvgResponseTime: "Avg. Response Time",
    AvgResolutionTime: "Avg. Resolution Time",
  },
  Ticket: {
    Assignees: "Assignees",
    NoAssignees: "There are no assignees",
    NoServiceCall: "There is no service call",
    NoTasks: "There are no tasks",
    MyTickets: "My Tickets",
    CreateTask: "Create Task",
    CreateServiceCall: "Create Service Call",
    AttachServiceCall: "Attach Service Call",
    Title: "Title",
    Description: "Description",
    DescriptionPlaceholder: "ex. Rearrange the boxes by color order",
    ServiceCallTitle: "Service Call Title",
    ProblemType: "Problem Type",
    CallType: "Call Type",
    Priority: "Priority",
    Customer: "Customer",
    ContactInfo: "Contact Info",
    Contact: "Contact",
    Technician: "Technician",
    Search: "Search",
    SearchPlaceholder: "Search...",
    Response: "Response",
    ResponsePlaceholder: "New Response",
    Attachments: "Attachments",
    NoFilesSelected: "No Files Selected",
    Upload: "Upload",
    Clear: "Clear",
    Respond: "Respond",
    TitlePlaceholder: "Broken Item No. 123",
    NewTitlePlaceholder: "New Title",
    Details: "Details",
    DetailsPlaceholder: "Ticket details",
    AIFeatures: "AI Features",
    Company: "Company",
    CompanyPlaceholder: "Your company",
    Department: "Department",
    DepartmentPlaceholder: "Choose a department",
    City: "City",
    CityPlaceholder: "City name",
    Location: "Location",
    LocationPlaceholder: "Address...",
    Status: "Status",
    Update: "Update",
    ServiceCall: "Service Call",
    Expenses: "Expenses",
    ItemNo: "Item No.",
    SerialNo: "Serial No.",
    Quantity: "Quantity",
    Times: "Times",
    Duration: "Duration",
    Remarks: "Remarks",
    Workers: "Workers",
    View: "View",
    EditContent: "Edit Content",
    Create: "Create",
    Attach: "Attach",
    Voucher: "Voucher",
    AddVoucher: "Add voucher",
    CreateTicket: "Create Ticket",
    Tasks: "Tasks",
    Tickets: "Tickets",
    NewTicket: "New Ticket",
    Person: "Person",
    Phone: "Phone",
    PhonePlaceholder: "Phone number",
    ID: "ID",
    Date: "Date",
    ServiceCallNumber: "Service Call Number",
    ServiceCallID: "Service Call ID",
    NotAvailable: "Not Available",
    Action: "Action",
    Manage: "Manage",
    Name: "Name",
    Cellular: "Cellular",
    Tel1: "Tel1",
    Tel2: "Tel2",
    Email: "Email",
    SapContact: "Sap Contact",
  },
  Department: {
    Users: "Users",
    Departments: "Departments",
    Action: "Action",
    Edit: "Edit",
    Delete: "Delete",
    Name: "Name",
    Description: "Description",
    Administrative: "Administrative",
    NewDepartment: "New Department",
  },
  Settings: {
    Cities: "Cities",
    General: "General",
    ForwardEmails: "Forward Emails",
    Save: "Save",
    CustomerTicketsRecipients: "Customer Tickets Recipients",
    FirewallRecipients: "Firewall Recipients",
    ItemsRecipients: "Items Recipients",
    ItemReminderRecipients: "Item Reminder Recipients",
    QnapRecipients: "Qnap Recipients",
    SapRecipients: "Sap Recipients",
    SubscriptionsRecipients: "Subscriptions Recipients",
    TasksRecipients: "Tasks Recipients",
    TicketsRecipients: "Tickets Recipients",
    UsersRecipients: "Users Recipients",
    VouchersRecipients: "Vouchers Recipients",
    FTP: "FTP",
    Enabled: "Enabled",
    Host: "Host",
    Username: "Username",
    Password: "Password",
    UploadPath: "Upload Path",
    UploadPathPlaceholder: "ex. /upload",
    Port: "Port",
    Secure: "Secure?",
    Messages: "Messages",
    Forwards: "Forwards",
    SMTP: "SMTP",
    OpenAI: "OpenAI",
    SAP: "SAP",
    Mail: "Mail",
    EmailConfigurationName: "Email Configuration Name",
    Subject: "Subject",
    SubjectPlaceholder: "ex. New Ticket [${ticket.id}]",
    FromEmail: "From Email",
    FromEmailPlaceholder: "Default",
    Forward: "Forward",
    ForwardPlaceholder: "Email received by the recipients forward list...",
    Direct: "Direct",
    DirectPlaceholder: "Email received by the targeted recipient...",
    AssistantID: "Assistant ID",
    APIKey: "API Key",
    OrganizationID: "Organization ID",
    Server: "Server",
    Database: "Database",
    Test: "Test",
    SMTPServerPlaceholder: "smtp.gmail.com",
    SecureConnection: "Secure Connection?",
    TestEmail: "Test Email",
    TestEmailPlaceholder: "john.doe@email.com",
  },
  User: {
    Users: "Users",
    Name: "Name",
    Edit: "Edit",
    Role: "Role",
    Email: "Email",
    EmailPlaceholder: "ex. john.doe@email.com",
    Username: "Username",
    FullName: "Full Name",
    Company: "Company",
    Phone: "Phone",
    NewPassword: "New Password",
    EmailConfirmation: "Email Confirmation (MFA)",
    OTP: "OTP (MFA)",
    Action: "Action",
    Delete: "Delete",
    NewUser: "New User",
  },
  Logs: {
    Logs: "Logs",
    Date: "Date",
    Reference: "Reference",
    Ref: "Ref",
    User: "User",
    Model: "Model",
    Action: "Action",
  },
  SecurityPage: {
    FirewallRecords: "Firewall Records",
    Date: "Date",
    IP: "IP",
    Subnet: "Subnet",
    Type: "Type",
    Action: "Action",
    Delete: "Delete",
    NewRecord: "New Record",
  },
  StoragePage: {
    Rename: "Rename",
    Delete: "Delete",
    FTPStorage: "FTP Storage",
    NewFolder: "New Folder",
    Path: "Path",
    Search: "Search",
    Name: "Name",
    Download: "Download",
    DownloadConfirm: "Do you want to proceed downloading this file?",
    Date: "Date",
    Size: "Size",
    Unknown: "Unknown",
    Folder: "Folder",
    SymbolicLink: "Symbolic Link",
    NewFile: "New File",
    Edit: "Edit",
  },
  FormTemplate: {
    Submit: "Submit",
    Cancel: "Cancel",
  },
  ConfirmDialog: {
    Question: "Are you sure you want to proceed?",
    Cancel: "Cancel",
    Confirm: "Confirm",
  },
  LanguageSwitcher: {
    Tooltip: "Click here to change your language",
    English: "English",
    Turkish: "Turkish",
  },
  DataView: {
    Search: "Search {{topic}}...",
  },
  Enums: {
    Roles: {
      User: "User",
      Agent: "Agent",
      Admin: "Admin",
    },
    RecordTypes: {
      Whitelist: "Whitelist",
      Blacklist: "Blacklist",
    },
    Priorities: {
      Low: "Low",
      Medium: "Medium",
      High: "High",
    },
    Statuses: {
      "Not reviewed": "Not reviewed",
      Incomplete: "Incomplete",
      "Pending confirmation": "Pending confirmation",
      Complete: "Complete",
    },
  },
  TextEditor: {
    TypeHere: "Type here...",
  },
};
