import { Box, Pagination, Stack } from "@mui/material";
import { ReactNode, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

interface CardListProps<T> {
  items: T[];
  count: number;
  renderItem: (item: T) => ReactNode;
}

const rowsPerPage = 25;

export default function CardList<T>(props: CardListProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const listRef = useRef<HTMLDivElement | null>(null);
  const page = useMemo(
    () => parseInt(searchParams.get("page") || "0"),
    [searchParams]
  );

  const handlePageChange = (event: any, page: number) => {
    setSearchParams(
      (sp) => {
        sp.set("page", (page - 1).toString());
        sp.set("rowsPerPage", rowsPerPage.toString());
        return sp;
      },
      { replace: true }
    );
    listRef.current?.scroll({ top: 0 });
  };
  return (
    <Stack
      spacing={1}
      flexGrow={1}
      overflow="auto"
      py={1}
      component={Box}
      ref={listRef}
    >
      {props.items.map((item) => props.renderItem(item))}
      <Pagination
        color="primary"
        count={Math.ceil(props.count / rowsPerPage)}
        page={page + 1}
        onChange={handlePageChange}
        shape="rounded"
      />
    </Stack>
  );
}
