import FormInput from "../../molecules/FormInput";
import { FormEvent, useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Ticket } from "../../../services/swagger";
import { SettingsService, TicketService } from "../../../services/api";
import useConfirm from "../../../hooks/useConfirm";
import useRefresh from "../../../hooks/useRefresh";
import Authorized from "../../atoms/Authorized";
import { useTranslation } from "react-i18next";
import { DropDownItem } from "../../molecules/Dropdown";
import useRole from "../../../hooks/useRole";
import { Box, Button, Paper, Typography } from "@mui/material";

interface TicketContactProps {
  ticket: Ticket;
}

export default function TicketContact(props: TicketContactProps) {
  const { t } = useTranslation("Ticket");
  const role = useRole();
  const { ticket } = props;
  const [company, setCompany] = useState(
    ticket.user?.company || ticket.company
  );
  const [cities, setCities] = useState<DropDownItem[]>([]);
  const [city, setCity] = useState(ticket.city);
  const [location, setLocation] = useState(ticket.location);
  const [person, setPerson] = useState(ticket.person);
  const [number, setNumber] = useState(ticket.number);
  const { confirm, ConfirmDialog } = useConfirm();
  const refresh = useRefresh();

  const loadCities = async () => {
    const res = await SettingsService.getCities();
    if (!res?.data?.data) return;
    setCities(res.data.data.map((e) => ({ name: e, value: e })));
    if (res.data.data.length > 0 && !city) setCity(res.data.data[0]);
  };

  useEffect(() => {
    loadCities();
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    confirm(async () => {
      if (!ticket?._id || !ticket.department?._id) return;
      const res = await TicketService.updateTicket({
        id: ticket._id,
        updateTicketBody: {
          city,
          location,
          priority: ticket.priority,
          status: ticket.status,
          title: ticket.title,
          person,
          number,
          company,
          departmentId: ticket.department._id,
          assignees: ticket.assignees
            .filter((u) => u._id?.toString())
            .map((user) => user._id?.toString() || ""),
        },
      });
      if (res?.data?.success) refresh();
    });
  };

  return (
    <Paper
      sx={{ p: 2, bgcolor: "background.default" }}
      component="form"
      onSubmit={handleSubmit}
    >
      <ConfirmDialog />
      <Stack spacing={2} height={1}>
        <Box>
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {t("ContactInfo")}
          </Typography>
        </Box>
        <Stack spacing={2} flexGrow={1} overflow="auto">
          {(company || role("agent")) && (
            <FormInput
              role="agent"
              field="company"
              label={t("Company")}
              type="string"
              placeholder={t("CompanyPlaceholder")}
              onChange={(v) => setCompany(v as string)}
              value={company}
              required
            />
          )}
          {(person || role("agent")) && (
            <FormInput
              role="agent"
              field="person"
              label={t("Person")}
              type="string"
              placeholder={t("Person")}
              onChange={(v) => setPerson(v as string)}
              value={person}
              required
            />
          )}
          {(number || role("agent")) && (
            <FormInput
              role="agent"
              field="number"
              label={t("Phone")}
              type="string"
              placeholder={t("PhonePlaceholder")}
              onChange={(v) => setNumber(v as string)}
              value={number}
            />
          )}
          {(city || role("agent")) && (
            <FormInput
              role="agent"
              field="city"
              label={t("City")}
              type="list"
              placeholder={t("CityPlaceholder")}
              onChange={(v) => setCity(v as string)}
              value={city}
              list={cities}
              required
            />
          )}
          {(location || role("agent")) && (
            <FormInput
              role="agent"
              field="location"
              label={t("Location")}
              type="string"
              placeholder={t("LocationPlaceholder")}
              onChange={(v) => setLocation(v as string)}
              value={location}
            />
          )}
        </Stack>
        <Authorized role="agent">
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="success"
              type="submit"
              sx={{ flexGrow: 1 }}
            >
              {t("Update")}
            </Button>
          </Stack>
        </Authorized>
      </Stack>
    </Paper>
  );
}
