import { useMemo, useState } from "react";
import MainTemplate from "../templates/MainTemplate";
import { PaginatedListTicket, Ticket } from "../../services/swagger";
import { TicketService } from "../../services/api";
import { Box, Button, Container, TableCell } from "@mui/material";
import PriorityChip from "../atoms/PriorityChip";
import { useNavigate } from "react-router";
import TicketStatus from "../atoms/TicketStatus";
import { useGlobalContext } from "../../contexts/Global";
import { useTranslation } from "react-i18next";
import DataView, { QueryParameters } from "../organisms/DataView";
import ItemCard from "../organisms/ItemCard";
import useBreakpoint from "../../hooks/useBreakpoint";

export default function MyTicketsPage() {
  const { t } = useTranslation("Ticket");
  const breakpoint = useBreakpoint();
  const { context } = useGlobalContext();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<PaginatedListTicket>({
    count: 0,
    docs: [],
  });

  const getTicketReadStatus = useMemo(() => {
    if (!context.user) return (ticket: Ticket) => ticket;
    return (ticket: Ticket): Ticket & { unRead?: boolean } => {
      let userUpdate = ticket.usersUpdated.find((userUpdate) => {
        if (typeof (userUpdate as any).username === "string")
          return (userUpdate as any).username === context.user?.username;
        else
          return (userUpdate.user as unknown as string) === context.user?._id;
      });
      const read = (userUpdate?.lastUpdate || 0) > ticket.lastUpdate;
      if (userUpdate) {
        return userUpdate.lastUpdate >= ticket.lastUpdate
          ? { ...ticket, unRead: !read }
          : { ...ticket, unRead: !read };
      } else return { ...ticket, unRead: !read };
    };
  }, [context.user]);

  const loadMyTickets = async (query: QueryParameters) => {
    const ticketsRes = await TicketService.getMyTicketList({
      search: query.search,
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (ticketsRes?.data?.data) setTickets(ticketsRes?.data?.data);
  };

  const navigateToMyTicket = (ticket: Ticket) => {
    navigate(`/my-tickets/${ticket._id}`);
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadMyTickets(query);
  };

  return (
    <MainTemplate>
      <Box
        bgcolor="background.paper"
        height={1}
        pt={breakpoint.md ? 4 : 1}
        pb={breakpoint.md ? 4 : 1}
      >
        <Container maxWidth="lg" disableGutters sx={{ px: 1 }}>
          <DataView
            name={t("MyTickets")}
            button={{
              text: t("NewTicket"),
              onClick: () => navigate("/my-tickets/create"),
            }}
            pagination
            count={tickets.count}
            items={
              context.user
                ? tickets.docs.map((ticket) => getTicketReadStatus(ticket))
                : []
            }
            renderItem={(ticket) => (
              <ItemCard
                ticket={ticket}
                onClick={() => navigateToMyTicket(ticket)}
              />
            )}
            headers={[
              {
                field: "id",
                name: t("ID"),
                type: "number",
              },
              {
                field: "date",
                name: t("Date"),
                type: "date",
              },
              {
                field: "title",
                name: t("Title"),
                type: "other",
                renderRowCell: (row: Ticket) => (
                  <TableCell
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 300,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row?.title}
                  </TableCell>
                ),
              },
              {
                field: "status",
                name: t("Status"),
                type: "other",
                renderRowCell: (row: Ticket) => {
                  return (
                    <TableCell>
                      <TicketStatus status={row.status} />
                    </TableCell>
                  );
                },
              },
              {
                field: "location",
                name: t("Location"),
                type: "other",
                renderRowCell: (row: Ticket) => (
                  <TableCell>{row.location || row.city || ""}</TableCell>
                ),
              },
              {
                field: "priority",
                name: t("Priority"),
                type: "other",
                renderHeaderCell: () => (
                  <TableCell width={50} align="center">
                    {t("Priority")}
                  </TableCell>
                ),
                renderRowCell: (row: Ticket) => {
                  return (
                    <TableCell align="center">
                      <PriorityChip priority={row.priority} />
                    </TableCell>
                  );
                },
              },
              {
                field: "delete",
                name: "",
                type: "other",
                renderHeaderCell: () => (
                  <TableCell align="center" width={70}>
                    {t("Action")}
                  </TableCell>
                ),
                renderRowCell: (row: Ticket) => (
                  <TableCell>
                    <Button
                      fullWidth
                      size="small"
                      variant="contained"
                      onClick={() => navigateToMyTicket(row)}
                    >
                      {t("View")}
                    </Button>
                  </TableCell>
                ),
              },
            ]}
            queryFunction={TicketService.getMyTicketListQuery}
            onChange={handleDataTableChange}
          />
        </Container>
      </Box>
    </MainTemplate>
  );
}
