import { useMatch, useNavigate } from "react-router";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useGlobalContext } from "../../contexts/Global";
import SideBarButtonBase from "./SideBarButtonBase";
import { Box, Collapse, Paper, Popover, Stack } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  URLSearchParamsInit,
  useSearchParams,
} from "react-router-dom";
import { objectToParamString, paramStringToObject } from "../../helpers/params";
import { Filter, SortDirection } from "../../services/swagger";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { sideBarWidth } from "../organisms/MainSideBar";

interface FilterOptions {
  field: string;
  value: string;
}

interface SubmenuProps {
  icon?: any;
  index: string;
  value: string;
  filter?: FilterOptions;
  subpage?: string;
}

interface SideBarButtonProps {
  icon?: any;
  value: string;
  page: string;
  filterField?: string;
  submenu?: SubmenuProps[];
}

export default function SideBarButton(props: SideBarButtonProps) {
  const { submenu } = props;
  const { context, setContext } = useGlobalContext();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const match = useMatch("/m/:page/*");
  const matchSub = useMatch("/m/:page/:subpage/*");
  const isPageMatched = match?.params.page === props.page;
  const filters = useMemo(() => {
    return paramStringToObject(
      searchParams.get("filters") || objectToParamString([])
    ) as Filter[];
  }, [searchParams]);
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  const submatched = useMemo(() => {
    let match = "";
    if (!submenu) return match;
    for (const sub of submenu) {
      if (sub.filter) {
        if (
          filters.filter(
            (f) => f.field === sub.filter?.field && Boolean(f.value)
          ).length === 1 &&
          filters.some(
            (f) => f.field === sub.filter?.field && f.value === sub.filter.value
          )
        )
          return sub.index;
      } else if (sub.subpage) {
        if (isPageMatched && matchSub?.params.subpage === sub.subpage)
          return sub.index;
      } else if (isPageMatched) match = sub.index;
    }
    return match;
  }, [filters]);

  useDidMountEffect(() => {
    if (!context.sidebar) setOpen(false);
  }, [context]);

  const handleClick = () => {
    if (!breakpoint.md) {
      setContext({ ...context, sidebar: false });
      navigate(`/m/${props.page}`);
    } else {
      if (!submenu) return navigate(`/m/${props.page}`);
      setOpen((o) => !o);
    }
  };
  useEffect(() => {
    if (!breakpoint.md || !context.sidebar) return;
    if (!isPageMatched && !submatched) return;
    setOpen(true);
  }, []);

  const handleSubmenuClick = ({
    filter,
    subpage,
  }: {
    filter?: FilterOptions;
    subpage?: string;
  }) => {
    const isNextPageSame = props.page === match?.params.page;
    const filters: Filter[] = isNextPageSame
      ? paramStringToObject(
          searchParams.get("filters") || objectToParamString([])
        )
      : [];
    const search: string = isNextPageSame
      ? searchParams.get("search") || ""
      : "";
    const sortField: string = isNextPageSame
      ? searchParams.get("sortField") || ""
      : "";
    const sortDirection: SortDirection = isNextPageSame
      ? (searchParams.get("sortDirection") as SortDirection | undefined) ||
        SortDirection.Desc
      : SortDirection.Desc;
    if (filter) {
      const filterIndex = filters.findIndex((e) => e.field === filter.field);
      if (filterIndex > -1) {
        filters[filterIndex].value = filter.value;
        for (let i = filterIndex + 1; i < filters.length; i++) {
          const eFilter = filters[i];
          if (i > filterIndex) {
            filters.splice(i, 1);
            continue;
          }
          if (eFilter.field === filter.field) eFilter.value = "";
        }
      } else
        filters.push({
          field: filter.field,
          type: "list",
          operator: "eq",
          value: filter.value,
        });
      const constructedSearchParams = createSearchParams({
        filters: objectToParamString(filters),
        sortField,
        sortDirection,
        search,
      }).toString();
      navigate({
        pathname: `/m/${props.page}`,
        search: constructedSearchParams,
      });
    } else if (subpage) {
      navigate(`/m/${props.page}/${subpage}`);
    } else {
      for (const i in filters) {
        if (Boolean(filters[i].value) && filters[i].field === props.filterField)
          filters[i].value = "";
      }
      const newSearchParams: URLSearchParamsInit = {
        sortField,
        sortDirection,
        search,
      };
      if (searchParams.get("filters"))
        newSearchParams.filters = objectToParamString(filters);
      const constructedSearchParams =
        createSearchParams(newSearchParams).toString();
      navigate({
        pathname: `/m/${props.page}`,
        search: constructedSearchParams,
      });
    }
    if (!context.sidebar) setOpen(false);
  };

  return (
    <Box width={1} position="relative">
      {open && !context.sidebar && (
        <Box
          component={Paper}
          top={7.5}
          right={-30}
          position="absolute"
          width={15}
          height={15}
          bgcolor="background.default"
          borderRadius={0}
          sx={{
            transform: "rotate(45deg) translateY(50%)",
          }}
        />
      )}
      <SideBarButtonBase
        buttonBaseProps={{ sx: { width: 1 }, ref: buttonRef }}
        matched={isPageMatched}
        value={props.value}
        onClick={handleClick}
        icon={props.icon}
      />
      {open && submenu && !context.sidebar && (
        <Popover
          open={open}
          anchorEl={(buttonRef as any).current}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: { ml: 2, p: 1 },
          }}
        >
          <Stack width={sideBarWidth} spacing={1 / 2}>
            {submenu.map((e) => {
              return (
                <SideBarButtonBase
                  key={e.value}
                  forceViewText
                  forceHideTooltip
                  matched={submatched === e.index}
                  onClick={() =>
                    handleSubmenuClick({
                      filter: e.filter,
                      subpage: e.subpage,
                    })
                  }
                  value={e.value}
                  icon={e.icon}
                />
              );
            })}
          </Stack>
        </Popover>
      )}
      {submenu && context.sidebar && (
        <Collapse
          in={open}
          sx={{
            ["& .MuiCollapse-wrapperInner"]: {
              pt: 1,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Stack ml={context.sidebar ? 2 : 0} flexGrow={1} spacing={1 / 2}>
            {submenu.map((e) => {
              return (
                <SideBarButtonBase
                  key={e.value}
                  matched={submatched === e.index}
                  onClick={() =>
                    handleSubmenuClick({
                      filter: e.filter,
                      subpage: e.subpage,
                    })
                  }
                  value={e.value}
                  icon={e.icon}
                />
              );
            })}
          </Stack>
        </Collapse>
      )}
    </Box>
  );
}
